import Image from 'next/future/image';
import Link from 'next/link';
import settings from '@/settings';
import { getEdgeUrl } from '@/lib/getEdgeUrl';

const DefaultHero = () => {
  const isNA = ['reebelo-us', 'reebelo-ca'].includes(settings.store);

  // End of Year Clearance
  const eoyLeft = getEdgeUrl('images/homepage/banner/left.png');
  const eoyLeftApac = getEdgeUrl('images/homepage/banner/apac-left.png');
  const eoyRight = getEdgeUrl('images/homepage/banner/right.png');
  const eoyRightApac = getEdgeUrl('images/homepage/banner/apac-right.png');
  const eoySmartphone = getEdgeUrl('images/homepage/banner/smartphone.png');
  const eoySmartwatch = getEdgeUrl('images/homepage/banner/smartwatch.png');
  const eoySmartwatchApac = getEdgeUrl(
    'images/homepage/banner/apac-smartwatch.png',
  );
  const eoySwitch = getEdgeUrl('images/homepage/banner/switch.png');
  const eoySwitchApac = getEdgeUrl('images/homepage/banner/apac-switch.png');

  return (
    <Link href="/collections/hot-deals" prefetch>
      <a
        className="relative flex
       h-full w-screen flex-col items-center justify-center overflow-hidden bg-teal-500 text-gray-700"
      >
        {/* Left Block  */}
        <div className="absolute hidden md:left-[-300px] md:block lg:left-[-180px] xl:left-[-80px]">
          <div className="h-[280px]">
            <Image
              src={isNA ? eoyLeft : eoyLeftApac}
              alt="Left Gadgets"
              loading="eager"
              height={800}
              width={800}
              className="h-full w-auto"
              priority
            />
          </div>
        </div>
        {/* Right Block */}
        <div className="absolute hidden md:right-[-280px] md:block lg:right-[-160px] xl:right-[-60px]">
          <div className="h-[280px]">
            <Image
              src={isNA ? eoyRight : eoyRightApac}
              alt="Right Gadgets"
              loading="eager"
              height={800}
              width={800}
              className="h-full w-auto"
              priority
            />
          </div>
        </div>
        {/* Smartphones */}
        <div className="absolute bottom-0 left-[-30px] xxs:left-[-20px] xs:left-0 md:hidden">
          <div className="h-[260px] sm:h-[280px]">
            <Image
              src={eoySmartphone}
              alt="Smartphone"
              loading="eager"
              height={800}
              width={800}
              className="h-full w-auto"
              priority
            />
          </div>
        </div>
        {/* Smartwatch */}
        <div className="absolute right-0 top-0 md:hidden">
          <div className="h-[280px] xs:h-[300px]">
            <Image
              src={isNA ? eoySmartwatch : eoySmartwatchApac}
              alt="Smartwatch"
              loading="eager"
              height={800}
              width={800}
              className="h-full w-auto"
              priority
            />
          </div>
        </div>
        {/* Nintendo Switch */}
        <div className="absolute bottom-0 right-[-120px] xxs:right-[-60px] xs:right-0 md:hidden">
          <div className="h-[150px] sm:h-[175px]">
            <Image
              src={isNA ? eoySwitch : eoySwitchApac}
              alt="Nintendo Switch"
              loading="eager"
              height={800}
              width={800}
              className="h-full w-auto"
              priority
            />
          </div>
        </div>
        <div className="relative">
          <div className="reebelo-container">
            <div className="flex flex-col items-center justify-center">
              <h1 className="mt-1.5 text-center text-lg font-light leading-tight xxs:text-xl xs:text-2xl md:text-3xl xl:text-4xl">
                <span className="font-semibold">The device</span> you want.{' '}
                <br />
                For a price <span className="font-semibold">you’ll love.</span>
              </h1>
              <div className="mt-5 flex h-10 items-center justify-center rounded-full border-2 border-gray-700 !bg-gray-700 px-5 text-xs font-semibold text-white sm:mt-10">
                Shop Deals
              </div>
            </div>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default DefaultHero;
